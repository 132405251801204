'use client';
import {useTranslation} from 'react-i18next';
import {RettsstiftelseSearchForm} from 'features/search/components/rettsstiftelse-search-form';
import {SearchCard} from 'features/search/components/search-card';
import {getEnv} from 'environment/public.environment';
import {useEffect, useReducer, useState} from 'react';
import {useSession} from 'next-auth/react';
import {useWindowEventListener} from 'common/hooks/use-event-listener';
import {CssBreakpoints} from 'styles/responsive';
import heftelserPerson from 'features/search/illustrations/heftelser_person.svg';
import {fnrValidator} from 'features/search/validators/fnr-validator';
import {Trans} from 'react-i18next';
import {useLang} from 'features/i18n/use-lang';
import {useRouter} from 'next/navigation';
import {generateTempPersonKey} from '../fetch-temp-person-key';

export const PersonSearchCard = () => {
  const {t} = useTranslation(['search']);
  const {status: sessionStatus, data: session} = useSession();
  const lang = useLang();
  const router = useRouter();

  const [isAuthenticated, setIsAuthenticated] = useState(sessionStatus === 'authenticated');
  useEffect(() => {
    if (sessionStatus !== 'loading') {
      setIsAuthenticated(sessionStatus === 'authenticated');
    }
  }, [sessionStatus]);

  const titleFromStatus = t('search:person.heading', isAuthenticated ? {} : {context: 'notAuth'});
  const [title, updateTitle] = useReducer(() => {
    if (window.innerWidth <= CssBreakpoints.XS) {
      return t('search:person.heading');
    }

    return titleFromStatus;
  }, titleFromStatus);

  useEffect(updateTitle, [sessionStatus]);
  useWindowEventListener('resize', updateTitle, []);

  const onSearch = async (fnr: string) => {
    try {
      const res = await generateTempPersonKey(fnr, session?.accessToken);
      if (res.status === 200) {
        const {key} = await res.json();
        return router.push(`/${lang}/oppslag/person/${key}`);
      } else {
        return router.push(`/${lang}/oppslag#person?register=losore&type=person&error=status_${res.status}`);
      }
    } catch {
      return router.push(`/${lang}/oppslag#person?register=losore&type=person&error=status_500`);
    }
  };

  return (
    <SearchCard
      id='person'
      requiresAuth
      imageSrc={heftelserPerson}
      title={title}
      subheader={t('search:person.subheading')}
    >
      <RettsstiftelseSearchForm
        searchType='person'
        register='losore'
        validator={fnrValidator}
        inputName='fodselsnummer'
        disableAutocomplete
        mask='****** *****'
        onSearch={onSearch}
      />
      <p>
        <a href={getEnv().links.brreg.omHeftelserPerson}>{t('search:person.description.heftelserInfoLink')}</a>
      </p>
      <p>
        <Trans i18nKey='search:person.description.minsideInfo'>
          PRELINK <a href={getEnv().links.brreg.minside}>LINK</a> POSTLINK
        </Trans>
      </p>
    </SearchCard>
  );
};
