'use client';
import {FC, JSX} from 'react';
import {ExternalLinkIcon} from '@navikt/aksel-icons';
import {Icon} from 'common/components/icon';
import {useTranslation} from 'react-i18next';

export const ExternalLink: FC<JSX.IntrinsicElements['a']> = ({children, ...props}) => {
  const {t} = useTranslation('common');
  return (
    <a {...props}>
      {children} <Icon aria-label={t('common:external-link')} icon={ExternalLinkIcon} />
    </a>
  );
};
