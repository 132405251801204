'use client';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';
import {Spinner} from '@digdir/designsystemet-react';

const useStyles = createUseStyles(
  {
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    loadingContainer: {
      display: 'inline-flex',
      gap: '0.5em',
      alignItems: 'center',
      animation: '$fadeIn 500ms linear',
    },
    loadingText: {
      display: 'inline-block',
    },
  },
  {name: 'Loading'}
);

export const Loading = () => {
  const {t} = useTranslation(['search']);
  const styles = useStyles();
  return (
    <div className={styles.loadingContainer}>
      <Spinner data-size='md' data-color='neutral' aria-label='Henter søkeresultater' />
      <p className={styles.loadingText}>{t('search:loading-message')}</p>
    </div>
  );
};
