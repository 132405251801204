'use client';
import {useTranslation} from 'react-i18next';
import {getEnv} from 'environment/public.environment';
import {RettsstiftelseSearchForm} from 'features/search/components/rettsstiftelse-search-form';
import {SearchCard} from 'features/search/components/search-card';
import {useRouter} from 'next/navigation';
import heftelserVirksomhet from 'features/search/illustrations/heftelser_virksomhet.svg';
import {orgnrValidator} from 'features/search/validators/orgnr-validator';
import {useLang} from 'features/i18n/use-lang';

export const VirksomhetSearchCard = () => {
  const {t} = useTranslation(['search']);
  const router = useRouter();
  const lang = useLang();

  return (
    <SearchCard
      id='virksomhet'
      imageSrc={heftelserVirksomhet}
      title={t('search:virksomhet.heading')}
      subheader={t('search:virksomhet.subheading')}
    >
      <RettsstiftelseSearchForm
        searchType='virksomhet'
        register='losore'
        validator={orgnrValidator}
        inputName='organisasjonsnummer'
        mask={'***\u200c ***\u200c ***'}
        onSearch={orgnr => router.push(`/${lang}/oppslag/virksomhet/${orgnr}`)}
      />
      <p>
        {t('search:virksomhet.description.paragraph1.beforeLink')}
        <a href={getEnv().links.brreg.virksomhetsopplysninger} rel='noopener noreferrer'>
          {t('search:virksomhet.description.paragraph1.link')}
        </a>
      </p>
      <p>
        <a href='#person'>{t('search:virksomhet.description.paragraph2.link')}</a>
      </p>
    </SearchCard>
  );
};
