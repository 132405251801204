'use client';

import {ExternalLink} from 'common/components/external-link';
import {getEnv} from 'environment/public.environment';
import {useLang} from 'features/i18n/use-lang';
import {RettsstiftelseSearchForm} from 'features/search/components/rettsstiftelse-search-form';
import {SearchCard} from 'features/search/components/search-card';
import heftelserMotorvogn from 'features/search/illustrations/heftelser_motorvogn.svg';
import {regnrValidator} from 'features/search/validators/regnr-validator';
import {useRouter} from 'next/navigation';
import {useTranslation} from 'react-i18next';

export const MotorvognSearchCard = () => {
  const {t} = useTranslation(['search']);

  const lang = useLang();
  const router = useRouter();

  return (
    <SearchCard
      id='motorvogn'
      imageSrc={heftelserMotorvogn}
      title={t('search:motorvogn.heading')}
      subheader={t('search:motorvogn.subheading')}
    >
      <p>
        {t('search:motorvogn.description.paragraph1.beforeLink')}
        <ExternalLink href={getEnv().links.statensVegvesen.merOmKjoretoy[lang]}>
          {t('search:motorvogn.description.paragraph1.link')}
        </ExternalLink>
      </p>
      <RettsstiftelseSearchForm
        searchType='motorvogn'
        register='losore'
        inputName='registreringsnummer'
        validator={regnrValidator}
        onSearch={regnr => {
          return router.push(`/${lang}/oppslag/motorvogn/${regnr}`);
        }}
      />
      <p>
        <a href={getEnv().links.brreg.omHeftelserMotorvogn}>{t('search:motorvogn.description.paragraph2.link')}</a>
      </p>
    </SearchCard>
  );
};
