'use client';

import {FC, ReactNode} from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(theme => ({
  title: {
    color: theme.colors.text.heading,
    fontWeight: 500,
    fontSize: '2em',
    marginBottom: '3rem',
  },
}));

export const PageTitle: FC<{children: ReactNode}> = ({children}) => {
  const styles = useStyles();
  return <h1 className={styles.title}>{children}</h1>;
};
